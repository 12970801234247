import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1069.000000 1080.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)">
          <path d="M0 5400 l0 -5400 5345 0 5345 0 0 5400 0 5400 -5345 0 -5345 0 0
-5400z m5509 2435 c294 -19 599 -125 856 -299 263 -177 437 -391 578 -706 24
-56 58 -152 54 -156 -2 -2 -25 39 -51 92 -193 394 -538 684 -1001 842 -139 47
-203 63 -375 94 -167 29 -509 32 -670 5 -235 -40 -451 -111 -654 -216 -66 -33
-122 -59 -124 -56 -3 3 0 5 6 5 6 0 17 6 25 14 18 18 137 95 197 127 25 13 47
26 50 30 3 3 21 12 40 21 114 52 155 68 245 98 55 18 107 37 116 41 66 31 478
82 589 72 14 -1 67 -5 119 -8z m-1764 -382 c11 -6 78 -29 148 -52 71 -22 141
-50 155 -61 15 -12 50 -37 78 -58 110 -80 208 -184 293 -310 64 -96 94 -156
286 -572 32 -69 71 -154 88 -190 16 -36 107 -234 202 -440 179 -390 208 -452
282 -610 25 -52 60 -129 78 -170 18 -41 49 -110 70 -153 20 -43 35 -80 33 -83
-3 -2 -27 33 -54 78 -26 46 -52 88 -56 93 -14 17 -102 156 -152 240 -26 44
-86 141 -133 215 -47 74 -90 144 -97 155 -6 11 -14 22 -17 25 -4 3 -16 23 -28
45 -24 45 -31 57 -83 137 -21 31 -38 62 -38 67 0 6 -3 11 -8 11 -4 0 -12 9
-17 20 -6 11 -46 77 -90 147 -44 70 -82 132 -84 138 -2 5 -29 48 -60 95 -31
47 -69 113 -86 147 -16 34 -35 62 -42 63 -7 0 -14 -24 -17 -62 -26 -271 -38
-407 -51 -563 -9 -99 -20 -227 -26 -285 -5 -58 -16 -179 -24 -270 -8 -91 -28
-315 -45 -499 -33 -360 -33 -364 16 -428 33 -44 73 -63 129 -63 33 0 45 -4 43
-12 -6 -17 -758 -19 -758 -3 0 6 33 13 74 17 111 10 195 60 239 142 31 59 35
76 52 221 8 72 31 267 50 435 20 168 44 379 55 470 10 91 28 246 40 345 11 99
27 236 35 305 8 69 22 184 30 255 9 72 19 166 22 211 5 77 4 84 -24 135 -69
124 -133 225 -178 278 -60 72 -124 140 -160 169 -22 19 -50 42 -62 53 -37 31
-174 97 -238 115 -33 9 -108 18 -167 21 -215 11 -78 53 157 49 66 -1 129 -6
140 -13z m-181 -222 c33 -11 90 -39 129 -62 l69 -41 -44 -47 c-105 -110 -230
-282 -310 -426 -424 -771 -385 -1813 95 -2530 44 -66 83 -122 86 -125 4 -3 34
-39 68 -80 150 -186 387 -374 623 -494 337 -173 658 -246 1080 -246 291 0 523
34 770 112 283 90 572 251 760 425 19 18 39 33 43 33 11 0 137 -126 137 -137
0 -5 -10 -17 -22 -28 -13 -10 -58 -50 -102 -87 -492 -426 -1289 -608 -2126
-488 -323 47 -715 176 -949 314 -34 20 -64 36 -67 36 -11 0 -213 138 -282 194
-115 92 -139 113 -235 211 -351 359 -570 797 -653 1311 -22 136 -30 506 -14
643 42 365 141 672 319 985 89 157 233 338 396 498 l60 59 55 -4 c30 -3 82
-14 114 -26z m3077 -236 c9 -55 24 -149 33 -210 9 -60 57 -366 106 -680 50
-313 97 -615 105 -670 8 -55 21 -140 29 -190 8 -49 28 -180 46 -290 47 -300
77 -452 111 -561 36 -118 44 -149 27 -114 -45 97 -78 170 -78 175 0 4 -12 32
-26 63 -24 54 -179 517 -204 612 -7 25 -16 54 -20 65 -9 22 -78 291 -117 455
-54 225 -64 269 -88 375 -23 104 -85 421 -85 438 0 21 -22 2 -41 -35 -22 -42
-108 -237 -155 -350 -13 -31 -38 -87 -55 -125 -17 -37 -56 -124 -86 -193 -30
-69 -72 -163 -93 -210 -21 -47 -61 -137 -88 -200 -28 -64 -64 -145 -80 -180
-76 -163 -280 -634 -325 -750 -13 -36 -35 -104 -48 -153 -18 -65 -28 -87 -39
-84 -18 3 -18 18 9 274 17 159 24 199 45 240 13 26 59 127 101 223 166 377
187 426 202 455 15 30 54 118 196 440 41 94 91 206 112 250 20 44 48 106 61
138 14 32 38 88 55 125 54 117 76 165 109 242 18 41 44 100 58 130 49 108 154
345 164 373 6 18 16 27 27 25 12 -2 20 -27 32 -103z m979 -1962 c-29 -221 -37
-266 -86 -473 -37 -156 -65 -256 -93 -335 -10 -27 -24 -68 -31 -90 -15 -48
-87 -205 -94 -205 -13 0 -36 13 -36 21 0 5 -16 34 -36 63 l-35 54 34 48 c162
229 298 584 363 949 8 44 15 82 15 85 1 3 3 -3 6 -12 2 -9 -1 -56 -7 -105z
m-252 -1188 c-3 -3 -9 2 -12 12 -6 14 -5 15 5 6 7 -7 10 -15 7 -18z m114 -90
c143 -109 299 -170 462 -180 108 -7 136 -12 136 -24 0 -15 -58 -23 -159 -23
-84 1 -106 5 -181 35 -90 35 -175 88 -256 158 -47 40 -91 89 -80 89 2 0 38
-25 78 -55z m-1160 -1292 c2 -76 3 -235 3 -353 l0 -215 -82 -3 c-69 -2 -83 0
-83 13 0 19 -19 19 -47 0 -11 -8 -39 -17 -62 -21 -34 -5 -47 -2 -76 17 -19 13
-39 37 -45 53 -5 16 -10 121 -10 233 0 163 3 210 15 233 30 58 112 77 173 40
18 -11 35 -20 37 -20 3 0 5 36 5 80 l0 80 84 0 84 0 4 -137z m477 -220 l3
-353 -86 0 c-70 0 -86 3 -86 15 0 20 -13 19 -57 -5 -21 -11 -49 -20 -63 -20
-32 0 -85 32 -102 62 -17 30 -25 430 -9 470 23 62 125 88 182 47 16 -11 33
-17 39 -14 5 3 10 40 10 81 l0 75 82 -3 83 -3 4 -352z m693 260 c3 -44 5 -48
31 -51 24 -3 27 -7 27 -43 0 -37 -2 -39 -31 -39 l-30 0 7 -187 c4 -104 11
-194 17 -200 5 -7 20 -13 33 -13 21 0 24 -4 24 -41 l0 -41 -84 3 c-96 4 -136
25 -147 75 -4 16 -7 113 -8 215 -1 178 -2 187 -21 192 -15 4 -20 14 -20 41 0
24 5 36 14 36 17 0 26 22 26 66 l0 34 79 0 80 0 3 -47z m-4059 -34 c12 -6 32
-20 44 -32 l22 -20 42 32 c60 44 109 43 155 -3 l34 -34 0 -261 0 -261 -87 0
-86 0 7 233 c5 196 4 236 -8 252 -12 16 -18 18 -33 9 -21 -13 -30 -112 -32
-352 l-1 -143 -81 3 -81 3 -2 245 c-1 233 -2 245 -20 248 -39 8 -46 -22 -46
-197 0 -91 -3 -196 -7 -233 l-6 -68 -78 0 -79 0 0 118 c0 64 3 195 7 290 l6
172 77 0 c76 0 78 -1 84 -26 l7 -27 22 20 c48 43 96 54 140 32z m1082 -14 c70
-35 75 -55 75 -327 l0 -238 -81 0 c-72 0 -81 2 -86 20 -7 25 -9 25 -36 0 -31
-30 -64 -35 -111 -18 -55 20 -66 45 -66 152 0 84 1 90 28 113 15 14 61 39 101
57 67 28 76 35 84 65 4 19 5 50 0 70 -7 29 -13 36 -33 36 -24 0 -25 -4 -30
-65 l-5 -65 -72 -3 -73 -3 0 51 c0 88 34 138 115 168 44 17 143 9 190 -13z
m737 13 c90 -27 127 -93 128 -225 l0 -62 -112 -3 -113 -3 -3 -91 c-3 -101 8
-125 47 -105 17 9 21 23 23 79 l3 67 45 3 c24 2 59 1 77 -3 30 -5 33 -9 33
-41 0 -19 -5 -55 -11 -80 -28 -111 -181 -158 -302 -92 -65 36 -80 76 -85 236
-5 150 7 233 42 271 48 54 143 75 228 49z m469 -3 c88 -31 119 -85 119 -206
l0 -78 -111 -3 -112 -3 2 -95 c2 -101 10 -122 39 -104 13 8 18 28 20 76 3 62
4 66 30 71 14 3 50 3 79 0 l53 -6 0 -56 c0 -45 -6 -66 -27 -99 -36 -56 -81
-77 -163 -77 -136 0 -193 56 -205 202 -11 127 1 259 28 307 42 75 149 106 248
71z m1439 -7 c84 -43 85 -46 88 -324 l4 -244 -81 0 c-74 0 -81 2 -81 20 0 26
-22 26 -37 0 -30 -47 -142 -27 -170 30 -13 26 -17 158 -7 197 6 21 89 69 178
103 24 9 26 14 26 64 0 67 -7 86 -31 86 -24 0 -30 -15 -34 -77 -2 -27 -3 -51
-4 -55 -1 -9 -112 -11 -134 -2 -12 4 -14 17 -10 62 9 88 37 124 123 155 39 14
127 6 170 -15z m740 -1 c58 -29 71 -57 81 -170 4 -56 8 -168 9 -249 l0 -148
-80 0 c-63 0 -80 3 -80 14 0 27 -20 28 -51 2 -37 -32 -69 -33 -120 -4 -45 25
-59 60 -59 146 0 81 18 114 80 142 138 64 135 62 138 106 4 58 -12 96 -41 92
-20 -3 -23 -10 -27 -68 l-5 -65 -72 -3 -73 -3 0 59 c0 111 77 176 200 170 36
-2 81 -12 100 -21z m-3976 -24 c3 -21 13 -96 21 -168 27 -228 37 -230 54 -9 7
84 14 160 16 170 2 11 4 25 4 32 1 8 22 12 71 12 80 0 77 6 60 -110 -6 -36
-17 -117 -25 -180 -48 -368 -49 -370 -229 -370 l-96 0 0 40 c0 35 3 40 24 40
48 0 49 15 11 216 -20 104 -42 221 -50 261 -23 109 -26 103 59 103 l74 0 6
-37z m1016 -23 c0 -64 -25 -137 -126 -365 -7 -16 -10 -33 -8 -37 3 -4 34 -8
70 -8 l64 0 0 -55 0 -55 -150 0 c-113 0 -150 3 -151 13 -6 84 22 185 91 332
20 44 40 98 40 111 0 10 -15 14 -54 14 -48 0 -54 3 -59 23 -4 12 -4 31 -2 42
2 11 4 26 4 33 1 9 36 12 141 12 l140 0 0 -60z m-1305 -1081 c68 -34 70 -41
73 -257 4 -219 -6 -268 -60 -311 -59 -47 -182 -38 -233 17 -39 42 -47 107 -37
306 7 153 11 180 29 204 45 61 150 80 228 41z m413 -40 c3 -51 -23 -204 -76
-459 l-18 -85 -73 -3 -73 -3 7 43 c4 24 29 132 56 241 27 108 49 202 49 207 0
5 -35 10 -77 12 l-78 3 4 45 c1 25 5 48 7 52 3 4 64 6 137 5 l132 -2 3 -56z
m302 41 c67 -34 75 -60 78 -262 4 -223 -6 -269 -70 -310 -84 -55 -207 -28
-247 54 -18 39 -21 61 -21 184 1 188 12 269 42 301 50 55 147 69 218 33z m418
0 c45 -20 72 -63 72 -115 0 -24 -2 -25 -70 -25 l-70 0 0 35 c0 39 -21 55 -45
35 -11 -9 -15 -33 -15 -87 l0 -74 26 20 c40 31 96 28 136 -8 33 -29 33 -30 36
-138 4 -134 -4 -168 -49 -205 -67 -54 -197 -47 -256 14 l-28 29 -3 197 c-2
126 0 210 8 234 12 42 42 78 80 95 40 18 130 15 178 -7z m431 0 c41 -20 71
-69 71 -114 0 -27 0 -27 -67 -24 l-68 3 -5 35 c-5 37 -35 54 -51 28 -9 -14
-13 -158 -4 -158 3 0 15 9 27 20 24 22 74 26 112 9 46 -21 58 -68 55 -200 -4
-115 -5 -118 -34 -149 -54 -56 -179 -67 -245 -20 -54 38 -60 67 -60 277 0 220
10 257 77 292 51 27 141 28 192 1z m362 10 c5 0 9 -124 9 -300 l0 -300 -75 0
-75 0 0 206 c0 122 -4 213 -10 225 -8 13 -21 19 -45 19 -32 0 -35 3 -35 28 0
25 7 31 53 50 28 12 64 33 78 46 27 25 55 36 77 30 8 -2 18 -4 23 -4z m349 -8
c50 -24 72 -73 67 -145 -3 -50 -7 -62 -32 -81 l-29 -23 29 -26 c29 -24 30 -29
33 -119 5 -122 -10 -161 -70 -188 -56 -26 -130 -25 -183 0 -60 29 -70 55 -70
176 0 89 3 106 21 131 l22 29 -24 27 c-19 23 -24 39 -24 86 0 71 29 120 80
138 52 18 140 16 180 -5z m440 -187 c5 -193 6 -200 26 -203 18 -3 25 13 63
130 74 226 84 253 100 266 11 9 43 12 101 10 l85 -3 5 -199 c5 -184 6 -200 24
-206 14 -4 20 -18 23 -47 3 -37 1 -42 -19 -45 -21 -3 -23 -8 -22 -55 l1 -53
-79 0 -78 0 0 55 0 56 -112 -3 -113 -3 -3 -52 -3 -53 -75 0 -74 0 0 55 0 55
-90 0 -90 0 0 50 c0 35 19 109 61 238 34 103 66 193 72 200 7 9 36 12 101 10
l91 -3 5 -200z m710 183 c73 -37 79 -59 82 -293 3 -185 1 -203 -17 -234 -26
-44 -78 -71 -137 -71 -93 0 -150 42 -169 124 -6 31 -10 120 -7 228 3 160 5
179 24 205 47 64 144 81 224 41z m416 2 c51 -25 69 -62 69 -144 0 -48 -4 -61
-23 -76 -28 -23 -28 -26 6 -55 26 -23 27 -26 27 -132 0 -92 -3 -112 -20 -135
-54 -73 -212 -78 -272 -9 -23 26 -28 43 -38 126 -7 68 1 77 66 73 l54 -3 3
-55 c4 -90 9 -102 40 -98 27 3 27 4 30 82 3 89 -10 116 -58 116 -23 0 -28 5
-32 28 -6 47 1 62 32 62 36 0 50 23 50 82 0 51 -19 84 -44 75 -12 -5 -16 -19
-16 -57 l0 -50 -65 0 -65 0 1 43 c0 68 19 110 59 129 49 25 142 24 196 -2z"/>
<path d="M6097 2384 c-4 -4 -7 -94 -7 -199 0 -158 3 -195 15 -205 21 -18 41
-6 47 27 2 15 2 107 -1 203 -6 166 -7 175 -26 178 -12 2 -24 0 -28 -4z"/>
<path d="M6567 2383 c-4 -3 -7 -93 -7 -198 0 -164 2 -195 16 -206 11 -9 20
-10 32 -2 15 9 17 35 20 192 1 114 -1 189 -8 201 -10 19 -40 27 -53 13z"/>
<path d="M4376 2138 c-22 -31 -22 -153 -1 -162 28 -10 45 5 46 43 6 133 5 141
-17 141 -7 0 -20 -10 -28 -22z"/>
<path d="M5154 2367 c-3 -13 -4 -41 -2 -63 3 -34 6 -39 31 -42 26 -3 27 -2 27
47 0 27 -3 56 -6 65 -9 25 -43 19 -50 -7z"/>
<path d="M5627 2384 c-4 -4 -7 -34 -7 -66 0 -56 1 -58 25 -58 25 0 26 1 23 62
-2 47 -7 64 -18 66 -9 1 -19 0 -23 -4z"/>
<path d="M7035 2136 c-23 -34 -17 -145 8 -160 31 -19 47 17 47 106 0 71 -2 78
-20 78 -11 0 -27 -11 -35 -24z"/>
<path d="M7775 2140 c-32 -36 -33 -151 0 -164 37 -14 45 3 45 95 0 95 -9 109
-45 69z"/>
<path d="M3532 1258 c-9 -9 -12 -71 -12 -224 0 -217 6 -251 37 -208 10 14 13
63 11 228 -3 207 -7 233 -36 204z"/>
<path d="M4246 1262 c-9 -15 -20 -385 -12 -415 4 -18 13 -27 26 -27 27 0 32
47 28 265 -3 158 -5 180 -20 183 -9 2 -19 -1 -22 -6z"/>
<path d="M4652 1048 c-8 -8 -12 -48 -12 -114 0 -81 3 -104 16 -115 30 -25 44
14 44 121 0 88 -8 120 -30 120 -3 0 -11 -5 -18 -12z"/>
<path d="M5082 1048 c-8 -8 -12 -48 -12 -114 0 -81 3 -104 16 -115 30 -25 44
14 44 121 0 88 -8 120 -30 120 -3 0 -11 -5 -18 -12z"/>
<path d="M5794 1256 c-3 -7 -4 -42 -2 -77 3 -56 5 -64 23 -64 17 0 20 8 23 54
2 30 1 65 -3 78 -6 25 -32 31 -41 9z"/>
<path d="M5790 922 c0 -77 3 -101 14 -105 28 -11 37 19 34 110 -3 83 -4 88
-25 91 -23 3 -23 1 -23 -96z"/>
<path d="M6185 1179 c-25 -68 -63 -225 -56 -232 5 -5 23 -7 41 -5 l33 3 -2
120 c-2 117 -5 142 -16 114z"/>
<path d="M6566 1182 c-11 -18 -64 -231 -58 -236 3 -3 20 -6 39 -6 l33 0 0 125
c0 118 -2 136 -14 117z"/>
<path d="M6954 1243 c-4 -14 -4 -114 -1 -221 4 -146 9 -197 19 -203 8 -6 18
-2 26 9 11 13 12 59 7 222 -4 113 -9 208 -12 213 -10 16 -33 5 -39 -20z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
